import { Options, Vue } from "vue-class-component";
import { ContractsRoutesEnum } from "../../router";
import { contractsService } from "@services/contracts.service";
import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
import { Contract } from "@/model/api/Contract";
import { FilterMatchMode } from "primevue/api";

@Options({
  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith(ContractsRoutesEnum.PREFIX)) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_CONTRACTS);
    }

    next();
  },
  beforeRouteLeave() {
    if (contractsService?.cancelPendingRequests) {
      contractsService.cancelPendingRequests();
    }
  },
})
export default class ContractsPage extends Vue {
  dataset      : Array<Contract> = null;

  filters = {
    global: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS
    },
    name: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS, 
    },
    'client.business_name': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS, 
    }
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_CONTRACTS
  }

  get newRoute() {
    return {
      name: ContractsRoutesEnum.CONTRACTS_DETAIL,
      params: { contractId: 'new' }
    }
  }

  refresh() {
    this.loadData();
  }

  goToDetail(contract: Contract) {
    this.$router.push({
      name: ContractsRoutesEnum.CONTRACTS_DETAIL,
      params: { contractId: contract.id }
    })
  }

  onDelete(contract: Contract) {
    this.$confirmMessage(this.$t(`contract.delete_confirm_message`))
      .then(r => {
        if (r) {
          this.deleteContract(contract);
        }
      })
  }

  private deleteContract(contract: Contract) {
    this.$waitFor(
      async () => {
        await contractsService.deleteBySystem(contract);
        this.refresh()
        this.$successMessage(this.$t(`contract.messages.delete_success`))
      }
    )
  }

  private loadData() {
    return this.$waitFor(
      async () => {
        this.dataset = await contractsService.getAll();
      }
    )
  }

  mounted() {
    this.loadData();
  }
}